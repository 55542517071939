<template>
  <form  @submit.prevent="createResetPassword" v-if="isTokenValid && !isResetSuccessful">
    <div class="_dashboard_content" v-if="tokenData">
        <div class="_dashboard_content_header">
            <div class="_dashboard__header_flex text-center">
                <h4>
                    <i class="bi bi-lock-fill"></i>
                    Sign into your account
                </h4>
                <div class="mt-1 mb-1">
                    By signing up you agree to Sabenzi's Data Policy and Terms and conditions
                </div>
            </div>
        </div>

        <div class="_dashboard_content_body">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class="form-group">
                        <label :class="fv.email.msg?'text-danger':''" for="email">
                            Email
                            <span v-if="fv.email.msg" > | {{fv.email.msg}}</span>
                        </label>
                        <input type="text" placeholder="Enter email"
                               required v-model.trim="fv.email.val"
                               @keyup="validate.validateFormData(fv,'email',true)"
                               class="form-control with-light" id="email">
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class="form-group">
                        <label :class="fv.password.msg?'text-danger':''" for="password">
                            New password
                            <span v-if="fv.password.msg" > | {{fv.password.msg}}</span>
                        </label>
                        <div class="input-group">
                            <input class="form-control with-light" required id="password"
                                   :type="fv.password.showPassword?'text':'password'" v-model="fv.password.val"
                                   :class="fv.password.msg?'border-danger':''"
                                   @keyup="validate.validateFormData(fv,'password',true)"
                                   placeholder="Enter password">
                            <div class="input-group-prepend rounded-right  cursor-pointer">
                                <span class="input-group-text b-0 rounded-right"
                                      id="basic-addon1"
                                      @click="togglePasswordVisibility">
                                    <i class="bi " :class="fv.password.showPassword?'bi-eye-slash-fill':'bi-eye-fill'"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class="form-group">
                        <label v-if="fv.captcha.msg" class="form-label text-danger" >
                            {{fv.captcha.msg}}
                        </label>
                        <base-captcha :key="captcha" :captchaVal="captcha" ></base-captcha>
                        <input  required v-model="fv.captcha.val"  min="7" max="7"
                                @keyup="validate.validateFormData(fv,'captcha',true)"
                                type="text" placeholder="Enter the text above" class="form-control with-light">

                    </div>
                </div>

                <div class="col-12" v-if="fv.reqFeedback.status">

                    <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                </div>


                <div class="col-xl-12 col-lg-12 col-12" v-if="fv.showSubmit.val">
                    <button type="submit" class="btn btn-save col-12">Sign into your account</button>
                </div>
                <base-spinner class="ml-3" v-else></base-spinner>

            </div>
        </div>

    </div>
    <base-card-response cardType="secondary"  v-else>

        <template #header></template>
        <template #default>
            <p>
                {{reqError}}
            </p>
        </template>

    </base-card-response>
  </form>

  <base-card-response  cardType="secondary" v-if="!isTokenValid && !isResetSuccessful">

      <template #header>Invalid reset request. </template>
      <template #default>
          <p>
              The token you provided is invalid and cannot be used to reset your password.
          </p>
          <p><router-link to="/dashboard"> <u style="color:#fff">Click this link </u></router-link></p>
      </template>

  </base-card-response>

  <base-card-response  cardType="success" v-if="isResetSuccessful">

      <template #header>Password reset was successful. </template>
      <template #default>
          <p>
              You have successfully reset your password.
          </p>
          <p><router-link to="/login"> <u style="color:#fff">Go back to the login page </u></router-link></p>
      </template>

  </base-card-response>
</template>


<script>
import { ref,reactive,inject } from 'vue'
import validators from '@/hooks/validation'

export default{

  name: 'CreateAuthResetPassword',
  props: {
      resetId:{
          type:String,
          default:"nothing"
      }
  },
  setup( props ) {

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');

    // initiate token, csrf_token and captcha variables with blank values
    let token = "";
    let csrf_token = "";
    const captcha = ref("");
    const isTokenValid = ref(false);

    const tokenData  = ref(false);
    const reqError  = ref("Loading login form....");



    // when the vue module is created, get the token value, using the try catch

            // use axios to get the data
            axios.get(coreUrl.backendUrl+'validate_password_reset_id/'+props.resetId,{withCredentials: true}).then((response) =>{
                // return the full token to the token variable
                token = response.data.data;
                tokenData.value = true;

                if(response.data.status){
                    // make the first 43 characters, the csrf token
                    csrf_token = token.substring(0,43)

                    // make the last 5 characters, the captcha
                    captcha.value = token.substring(43,48)
                    isTokenValid.value = true;
                }else{
                    captcha.value = "bad request"
                }




            }).catch((error)=>{
                // if an error occours, do this
                tokenData.value = false;
                reqError.value = "Login form can't be loaded at this time. Try again";
                console.log(error)
                // reqError.value = error.response.data.message.req_msg?errorMsg:errorMsg;
            })




     // create variables for the values to be submitted
    const fv = reactive({
        email : {status:false, val:"", msg:false, validate:["required","email"]},
        password : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                      {option:"textMin",val:10},
                                                                                      {option:"textMax",val:64}
                                                                                     ]},
        captcha : {status:false, val:"", msg:false, validate:["required","alphaNumeric"]},
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:false, val:true}
    })


    // toggle password visibility
    function togglePasswordVisibility(){
        fv.password.showPassword = !fv.password.showPassword;
    }

    // get the current status of the created account
    const isResetSuccessful = ref(false);

    // create the account using async
    async function createResetPassword(){

        fv.showSubmit.val = false;

        // load the form data , and append all the necessary fields
        let formData = new FormData();

        formData.append("fv_email", fv.email.val);
        formData.append("fv_reset_id", props.resetId);
        formData.append("fv_password", fv.password.val);
        formData.append("fv_csrf_token",csrf_token+fv.captcha.val);
        formData.append("fv_account_type","individual");

        // post the data using axios
        try{

            const response = await axios.put(
                coreUrl.backendUrl+'create_password_reset',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data
            const responseData = response.data;

            if(responseData.status){
                // update is account created status
                isResetSuccessful.value = true;
                isTokenValid.value = false;
                fv.reqFeedback.status = false;

                fv.showSubmit.val = true;
            }else{

                // redo the captcha sequence
                token =  responseData.data?responseData.data:"bad";
                csrf_token =  responseData.data?token.substring(0,43):"bad";
                captcha.value =  responseData.data?token.substring(43,48):"bad";

                for(const key in responseData.message){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = responseData.message[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message['req_msg'];
                fv.reqFeedback.val = "danger";
                fv.showSubmit.status = false;
                fv.showSubmit.val = true;
            }




        }catch(error){


            // load the error to the appropriate fiel

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = "We had issues processing your request";
            fv.reqFeedback.val = "danger";
            fv.showSubmit.status = false;
            fv.showSubmit.val = true;

            // end load the error to the appropriate field

        }

     }



    return {
        fv,
        validate,
        tokenData,
        reqError,
        togglePasswordVisibility,
        createResetPassword,
        captcha,
        isResetSuccessful,
        isTokenValid
     }
  }
}
</script>
